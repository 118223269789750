import { Component } from "react";
import ProductList from "./Componenets/Productlist/ProductList";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Componenets/LayOut/Layout";
import ProductAdd from "./Componenets/ProductAdd/ProductAdd";
import axios from "axios";

export default class App extends Component{
  state = {
    products : []
  }

  componentDidMount() {
    this.fetchProducts();
  }

  fetchProducts = () => {
    axios.get("https://www.assignmentyoussef.online/get_products.php")
    .then((response)=> {
      this.setState({ products : response.data })
    })
  }

  render(){
    let routers = createBrowserRouter([
      {path:'' , element:<Layout/> , children:[
        {index : true , element: <ProductList products={this.state.products} />},
        {path : 'addproduct' , element: <ProductAdd/>},
      ]}
    ])
    return <>
    <RouterProvider router={routers}>
    </RouterProvider>
    </>
  }
}