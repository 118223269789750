import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";

class ProductAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sku: '',
            name: '',
            price: '',
            selectedType: '',
            size: '',
            height: '',
            width: '',
            length: '',
            weight: '',
            notification: '', // For displaying notifications
            errors: {} // To keep track of validation errors
        };
    }

    handleTypeChange = (e) => {
        const selectedType = e.target.value;
        this.setState({ selectedType, size: '', height: '', width: '', length: '', weight: '' });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    validateForm = () => {
        const { sku, name, price, selectedType, size, height, width, length, weight } = this.state;
        const errors = {};
        let isValid = true;

        if (!sku) errors.sku = "SKU is required.";
        if (!name) errors.name = "Name is required.";
        if (!price || isNaN(price)) errors.price = "Valid price is required.";

        if (selectedType === 'dvd' && !size) errors.size = "Size is required for DVD.";
        if (selectedType === 'furniture') {
            if (!height || isNaN(height)) errors.height = "Valid height is required.";
            if (!width || isNaN(width)) errors.width = "Valid width is required.";
            if (!length || isNaN(length)) errors.length = "Valid length is required.";
        }
        if (selectedType === 'book' && (!weight || isNaN(weight))) errors.weight = "Valid weight is required.";

        if (Object.keys(errors).length > 0) {
            isValid = false;
            this.setState({ errors });
        } else {
            this.setState({ errors: {} });
        }

        return isValid;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            this.setState({ notification: "Please, provide the data of indicated type." });
            return;
        }

        const { sku, name, price, selectedType, size, height, width, length, weight } = this.state;
        let data = { sku, name, price, type: selectedType };

        if (selectedType === 'dvd') {
            data.size = size;
        } else if (selectedType === 'furniture') {
            data.height = height;
            data.width = width;
            data.length = length;
        } else if (selectedType === 'book') {
            data.weight = weight;
        }

        try {
            const response = await fetch("https://www.assignmentyoussef.online/add_product.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                this.props.navigate("/");
            } else {
                this.setState({ notification: result.message });
            }
        } catch (error) {
            console.error("Error:", error);
            this.setState({ notification: "An error occurred while adding the product." });
        }
    };

    render() {
        const { selectedType, sku, name, price, size, height, width, length, weight, notification, errors } = this.state;

        return (
            <>
                <header className="header">
                    <h1 className="product">Product Add</h1>
                    <div className="button-container">
                        <button onClick={this.handleSubmit} className="btn btn-success">Save</button>
                        <Link to="/">
                            <button className="btn btn-danger">Cancel</button>
                        </Link>
                    </div>
                </header>
                {notification && <p className="alert alert-warning">{notification}</p>}
                <div id="product_form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="container">
                            <div className="form-group row mb-3">
                                <label className="col-form-label col-1">SKU</label>
                                <div className="col-3">
                                    <input type="text" className="form-control" id="sku" value={sku} onChange={this.handleInputChange} required />
                                    {errors.sku && <div className="text-danger">{errors.sku}</div>}
                                </div>
                            </div>
                            <div className="form-group row mb-3">
                                <label className="col-form-label col-1">Name</label>
                                <div className="col-3">
                                    <input type="text" className="form-control" id="name" value={name} onChange={this.handleInputChange} required />
                                    {errors.name && <div className="text-danger">{errors.name}</div>}
                                </div>
                            </div>
                            <div className="form-group row mb-3">
                                <label className="col-form-label col-1">Price($)</label>
                                <div className="col-3">
                                    <input type="text" className="form-control" id="price" value={price} onChange={this.handleInputChange} required />
                                    {errors.price && <div className="text-danger">{errors.price}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="container" id="productType">
                            <div className="row mb-3">
                                <label className="col-2 mb-3">Type Switcher</label>
                                <div className="col-3">
                                    <select className="form-control" value={selectedType} onChange={this.handleTypeChange} required>
                                        <option value="" disabled>Select type</option>
                                        <option value="dvd">DVD</option>
                                        <option value="furniture">Furniture</option>
                                        <option value="book">Book</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {selectedType === 'dvd' && (
                            <div className="container" id="dvd">
                                <div className="form-group row mb-3">
                                    <label className="col-form-label col-1">Size(MB)</label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="size" value={size} onChange={this.handleInputChange} required />
                                        {errors.size && <div className="text-danger">{errors.size}</div>}
                                    </div>
                                    <p>Please, provide size in MB.</p>
                                </div>
                            </div>
                        )}
                        {selectedType === 'furniture' && (
                            <div className="container" id="furniture">
                                <div className="form-group row mb-3">
                                    <label className="col-form-label col-1">Height(CM)</label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="height" value={height} onChange={this.handleInputChange} required />
                                        {errors.height && <div className="text-danger">{errors.height}</div>}
                                    </div>
                                </div>
                                <div className="form-group row mb-3">
                                    <label className="col-form-label col-1">Width(CM)</label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="width" value={width} onChange={this.handleInputChange} required />
                                        {errors.width && <div className="text-danger">{errors.width}</div>}
                                    </div>
                                </div>
                                <div className="form-group row mb-3">
                                    <label className="col-form-label col-1">Length(CM)</label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="length" value={length} onChange={this.handleInputChange} required />
                                        {errors.length && <div className="text-danger">{errors.length}</div>}
                                    </div>
                                    <p>Please, provide dimensions in CM.</p>
                                </div>
                            </div>
                        )}
                        {selectedType === 'book' && (
                            <div className="container" id="book" >
                                <div className="form-group row mb-3">
                                    <label className="col-form-label col-1">Weight(KG)</label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" id="weight" value={weight} onChange={this.handleInputChange} required />
                                        {errors.weight && <div className="text-danger">{errors.weight}</div>}
                                    </div>
                                    <p>Please, provide weight in KG.</p>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </>
        );
    }
}

function ProductAddWithNavigation(props) {
    const navigate = useNavigate();
    return <ProductAdd {...props} navigate={navigate} />;
}

export default ProductAddWithNavigation;
