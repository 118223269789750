import React, { Component } from "react";
import Products from "../Products/Products";
import { Link } from "react-router-dom";
import axios from "axios";
import '../Productlist/ProductList.css';

export default class ProductList extends Component {
  state = {
    products: [],
    selectedSKUs: []
  };

  componentDidMount() {
    this.fetchProducts();
  }

  fetchProducts = () => {
    axios.get('https://www.assignmentyoussef.online/get_products.php')
      .then(response => {
        this.setState({ products: response.data });
      })
      .catch(error => {
        console.error("There was an error fetching the products!", error);
      });
  };

  handleCheckboxChange = (sku) => {
    this.setState(prevState => {
      const selectedSKUs = prevState.selectedSKUs.includes(sku)
        ? prevState.selectedSKUs.filter(id => id !== sku)
        : [...prevState.selectedSKUs, sku];
      return { selectedSKUs };
    });
  };

  handleDelete = () => {
    axios.post('https://www.assignmentyoussef.online/delete_products.php', {
      skus: this.state.selectedSKUs
    })
    .then(response => {
      console.log(response.data.message);
      this.fetchProducts(); 
    })
    .catch(error => {
      console.error("There was an error deleting the products!", error);
    });
  };

  render() {
    return (
      <>
        <header className="header">
          <h1 className="product">Product List</h1>
          <div className="button-container">
            <Link to="addproduct">
              <button className="btn btn-success">ADD</button>
            </Link>
            <button 
              id="delete-product-btn"
              className="btn btn-danger" 
              onClick={this.handleDelete}
            >
              MASS DELETE
            </button>
          </div>
        </header>
        <div className="product-container">
          <div className="p-4">
            <div className="row gy-4">
              {this.state.products.map((product) => (
                <Products 
                  key={product.sku} 
                  productDetails={product} 
                  onCheckboxChange={this.handleCheckboxChange} 
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
