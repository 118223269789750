import React, { Component } from "react";
import '../Products/Products.css';

export default class Products extends Component {
  handleCheckboxChange = (event) => {
    this.props.onCheckboxChange(this.props.productDetails.sku);
  };

  render() {
    const { sku, name, price, size, type, height, width, length, weight } = this.props.productDetails;
    return (
      <>
        {type === 'dvd' && (
          <div className="col-md-3 d-flex justify-content-center">
            <div className="product-card position-relative">
              <input type="checkbox" className="delete-checkbox" onChange={this.handleCheckboxChange}/>
              <div className="product-details">
                <h4>{sku}</h4>
                <h4>{name}</h4>
                <h4>{price}</h4>
                <h4>Size: {size} MB</h4>
              </div>
            </div>
          </div>
        )}
        {type === 'furniture' && (
          <div className="col-md-3 d-flex justify-content-center">
            <div className="product-card position-relative">
              <input type="checkbox" className="delete-checkbox" onChange={this.handleCheckboxChange}/>
              <div className="product-details">
                <h4>{sku}</h4>
                <h4>{name}</h4>
                <h4>{price}</h4>
                <h4>Dimension: {height}x{width}x{length}</h4>
              </div>
            </div>
          </div>
        )}
        {type === 'book' && (
          <div className="col-md-3 d-flex justify-content-center">
            <div className="product-card position-relative">
              <input type="checkbox" className="delete-checkbox" onChange={this.handleCheckboxChange}/>
              <div className="product-details">
                <h4>{sku}</h4>
                <h4>{name}</h4>
                <h4>{price}</h4>
                <h4>Weight: {weight} KG</h4>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
